<template>
    <v-container fluid>
        <div v-if="!loadingAirlines && airlines.length">
            <v-row>
                <v-col cols="12" sm="9" md="10" lg="10">
                  <div class="d-flex justify-space-between">
                    <h3 class="text-h6 blueDark--text">Contracts airlines</h3>
                    <v-btn v-if="$store.state.user.role === 'ats_admin'" elevation="0" color="blueDark" class="mr-15" small dark @click="$router.push({name: 'addContract'})"><v-icon left>mdi-plus-circle</v-icon>add contract</v-btn>
                  </div>
                    <div class="d-flex flex-wrap" >
                    <airline-card v-for="item in airlines" :key="item.code" :airline="item" />
                    </div>
                </v-col>
                <v-col cols="12" sm="3" md="2" lg="2" :style="$vuetify.breakpoint.smAndUp ? 'border-left: 1px solid #e9e9e9;' : ''">
                  <h3 class="text-h6 blueDark--text">Recently Updated</h3>
                  <div v-if="lastUpadatedCotracts.length">
                    <v-card @click="$router.push({name: 'contractDetails', params: {id: item.id}})" outlined class="my-1 pa-1" v-for="item in lastUpadatedCotracts" :key="item.id">
                      <p class="body-1 blueDark--text">{{item.name}}, {{item.region.name}}</p>
                      <span class="body-2" v-for="airline in item.airlineCodes" :key="airline">{{airline}},</span>
                    </v-card>
                  </div>
                  <p v-else class="body-2 my-10 text-center"><v-icon>mdi-alert</v-icon> No recently updated contracts!</p>
                </v-col>
            </v-row>
        </div>
        <div v-else-if="!loadingAirlines && !airlines.length">
            <p class="headline my-10 text-center"><v-icon>mdi-alert</v-icon> No Airlines found!</p>
        </div>
    </v-container>
</template>

<script>
import airlineCard from '@/components/contracts/airlineCard'
import { contractAirlines, lastUpdatedContracts, headers } from '../../links'

export default {
  name: 'contractlist',
  components: {
    'airline-card': airlineCard
  },
  data () {
    return {
      loadingAirlines: true,
      airlines: [],
      lastUpadatedCotracts: []
    }
  },
  created () {
    this.$store.dispatch('setLoadingState', true)
    this.$http.get(contractAirlines, { headers: headers(this.$cookies.get('userToken')) }).then(response => {
      this.airlines = response.body.data
    }).catch(err => {
      console.log(err)
    }).finally(() => {
      this.loadingAirlines = false
      this.$store.dispatch('setLoadingState', false)
    })
    this.$http.get(lastUpdatedContracts, { headers: headers(this.$cookies.get('userToken')) }).then(response => {
      this.lastUpadatedCotracts = response.body.data
    }).catch(err => {
      console.log(err)
    })
  }
}
</script>
